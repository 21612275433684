<script>
import cognitoMixin from '@layers/web/mixins/cognito.mixin'

/**
 * Initializes cognito
 */
export default defineNuxtComponent({
  mixins: [cognitoMixin],

  render () {
    return null
  },
})
</script>
